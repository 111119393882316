<template>
  <v-dialog :value="value" persistent width="800" @click:outside="closeNote">
    <v-card class="py-0 fill">
      <v-card-title class="font-weight-medium">Note</v-card-title>
      <v-card-subtitle>
        {{ item.page_name }} {{ item.position_name }}
        {{ item.feature_text }}
      </v-card-subtitle>
      <v-card-text>
        <v-textarea
          dense
          hide-details
          outlined
          rows="1"
          readonly
          v-model="modifiedNote">
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text class="text-capitalize" @click="closeNote">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    item: Object
  },
  data() {
    return {
      isOpen: false,
      modifiedNote: ''
    }
  },
  computed: {
    originalNote() {
      return this.item.feature_notes
    }
  },
  watch: {
    item: {
      handler(newValue) {
        if (newValue) {
          const { feature_notes } = newValue
          this.modifiedNote = feature_notes
        }
      }
    }
  },
  methods: {
    closeNote() {
      this.$emit('update:value', false)
    }
  }
}
</script>